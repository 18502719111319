html, body {
    font-size: 12px;
    background: #171f32;
    color: #fff;
} 

.navbar .navbar-nav .nav-link:not(.btn) {
    font-size: 12px;
}

.page-header {
    min-height: 450px; 
}

.index-page .page-header {
    height: 750px;
}
a {
    color: rgb(44, 142, 37);
}
a:hover {
    color: rgb(44, 142, 37);
    font-weight: 800;
    text-decoration: none;
}
a.top-bar-icon {
    font-size: 20px;
    margin: 0px 5px; 
    color: #6c757d;
    z-index: 1031;
    line-height: 34px;
}
a.top-bar-icon:hover { 
    color: #007bff;
}

.navbar.navbar-transparent {
    padding: 0 20px !important;
}

.main {
    position: relative;
    background: #ffffff00;
}

.clear-filter[filter-color="blue"] {
    background: rgba(44, 44, 44, 0.2);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(3, 161, 224, 0.6));
} 
.DateInput_input {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000; 
    padding: 7px 11px; 
}
.DateInput { 
    width: 100px; 
}
.form-group .input-group-text, .input-group .input-group-text {
    padding: 0px 15px;
}

.wishlist {
    display: inline-block;
    width: 400px;
    margin-bottom: 10px;
    border-top: 1px solid #969696;
    border-bottom: 1px solid #969696;
    border-right: 1px solid #969696;
}

.card-signup.card button {
    width: 40px;
    height: 40px;
    margin: 0px 5px;
}
.card-signup.card button i {
    color: #fff;
    font-size: 16px;
    padding: 2px;
}
.header-filter[filter-color=black]{
    background: linear-gradient(0deg,rgba(0,0,0,.5),rgba(0,0,0,.9)) !important;
}

.card-signup .card-footer {
    margin-bottom: 10px;
    margin-top: 24px;
    padding: 0;
}
.clear-filter[filter-color="black"] {
    background: rgba(44, 44, 44, 0.2);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));
}

.page-header .page-header-image {
    z-index: 0;
}

.porfolio-content {
    cursor: pointer;
    opacity: .5;
}
.porfolio-content:hover {
    cursor: pointer;
    opacity: 1;
}
.btn.btn-icon, .navbar .navbar-nav > a.btn.btn-icon {
    height: 2.375rem;
    min-width: 2.375rem;
    width: 2.375rem;
    padding: 0;
    font-size: 0.9375rem;
    overflow: hidden;
    position: relative;
    line-height: normal;
}

footer ul.social-buttons li {
    margin: 5px; 
}

.info.info-horizontal ul.social-buttons, .card-body ul.social-buttons {
    display: inline-flex;
    list-style: none;
    padding: 0;
}
.info.info-horizontal ul.social-buttons {
    float: left;
}
.info.info-horizontal ul.social-buttons li , .card-body ul.social-buttons li {
     margin: 5px;
}
.info.info-horizontal ul.social-buttons li a, .card-body ul.social-buttons li a{
    text-decoration: none;
}
.info.info-horizontal ul.social-buttons li a:hover, .card-body ul.social-buttons li a:hover {
    text-decoration: none;
    color: #007bff;
}

.grouw-business .card-background .card-title {
    cursor: pointer;
    color: #fff;
    margin-top: 130px;
    margin-left: 10%;
    margin-right: 10%;
    background: #20273ed1;
    padding: 25px 20px;
    bottom: 30px;
    position: absolute;
}
.grouw-business .card-background .card-title h3 {
    color: rgb(63, 223, 20);
    font-weight: 800 ;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 0px;
}

.grouw-business .card-background .card-title p {
     color: #fff;
     font-weight: 400 ;
     display: none;
}

.card-fashion:not(.card-background):before { 
    border-left-color: #20273e; 
}
.grouw-business .card-background:hover >  .card-title{ 
    background: #20273e; 
}
.grouw-business .card-background:hover >  .card-title p{ 
    display: block;
}
.grouw-business .card-background:hover > .card-title h3 { 
    margin-bottom: 15px;
}
.info .info-title {
    color: aliceblue;
}
.info p {
    color: #e3e3e3; 
}
.btn.btn-icon:not(.btn-footer) i.now-ui-icons, .btn.btn-icon:not(.btn-footer) i.fab, .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) i.now-ui-icons, .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) i.fab {
    line-height: 1.6726rem;
    width: 25px;
}

.card-fashion:not(.card-background):before {
    border-left-color: #ffffff;
}

.card-fashion {
    min-height: 500px;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    display: block;
}

ul.nav-pills-primary.nav-pills-just-icons.justify-content-center.portfolio.nav.nav-pills li a {
    padding-top: 7px;
    background: transparent;
    border: 1px solid;
}

.portfolio-list-image {
    background-size: cover !important ;
}